import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import "./styles.css";

interface Upgrade {
  name: string;
  cost: { [key: string]: number };
  description: string;
  purchased: boolean;
  maxLevel?: number;
  level?: number;
  tier: number;
  requires?: string[];
}

interface Achievement {
  id: string;
  name: string;
  description: string;
  condition: (stats: GameStats) => boolean;
  unlocked: boolean;
  reward: { type: string; amount: number };
}

interface GameStats {
  clicks: number;
  totalHarvested: number;
  totalWheatGrown: number;
  totalCoinsEarned: number;
  totalUpgradesPurchased: number;
  prestigeCount: number;
}

interface Weather {
  type: 'sunny' | 'rainy' | 'stormy' | 'drought';
  modifier: number;
  duration: number;
}

interface Recipe {
  name: string;
  ingredients: { [key: string]: number };
  output: { [key: string]: number };
  unlocked: boolean;
  type: string;
  requires?: string[];
}

interface Quest {
  id: string;
  name: string;
  description: string;
  requirements: { [key: string]: number };
  reward: { [key: string]: number };
  completed: boolean;
  tier: number;
  hidden?: boolean;
}

interface RecipeCardProps {
  recipe: Recipe;
  onCraft: (recipe: Recipe) => void;
  inventory: { [key: string]: number };
  addFloatingText: (text: string, x: number, y: number, color: string) => void;
}

const RecipeCard: React.FC<RecipeCardProps> = ({ recipe, onCraft, inventory, addFloatingText }) => {
  const canCraft = Object.entries(recipe.ingredients).every(
    ([resource, amount]) => inventory[resource] >= amount
  ) && !(
    (recipe.output.millStructure && inventory.millStructure > 0) ||
    (recipe.output.ovenStructure && inventory.ovenStructure > 0) ||
    (recipe.output.silo && inventory.silo > 0) ||
    (recipe.output.marketStall && inventory.marketStall > 0)
  );

  const requirementsMet = !recipe.requires || recipe.requires.every(req => {
    switch (req) {
      case "Wheat Mill": return inventory.millStructure > 0;
      case "Baker's Oven": return inventory.ovenStructure > 0;
      case "Storage Silo": return inventory.silo > 0;
      case "Market Stall": return inventory.marketStall > 0;
      case "Basic Tools": return inventory.tools > 0;
      default: return true;
    }
  });

  // Helper function to get emoji for resource
  const getEmoji = (resource: string) => {
    const emojiMap: { [key: string]: string } = {
      wood: "🪵",
      rocks: "🪨",
      clay: "🟤",
      tools: "🔨",
      bricks: "🧱",
      flour: "🛍️",
      millStructure: "⚙️",
      ovenStructure: "🏭",
      silo: "🏗️",
      marketStall: "🏪",
      wheat: "🌾",
      bread: "🍞"
    };
    return emojiMap[resource] || "";
  };

  return (
    <div className={`recipe-card ${canCraft ? 'available' : 'unavailable'} ${!requirementsMet ? 'locked' : ''}`}>
      <h3>{recipe.name}</h3>
      {!requirementsMet && (
        <div className="requirements">
          Required structures: 
          {recipe.requires?.map(req => (
            <span 
              key={req} 
              className={
                (() => {
                  switch (req) {
                    case "Wheat Mill": return inventory.millStructure > 0;
                    case "Baker's Oven": return inventory.ovenStructure > 0;
                    case "Storage Silo": return inventory.silo > 0;
                    case "Market Stall": return inventory.marketStall > 0;
                    case "Basic Tools": return inventory.tools > 0;
                    default: return false;
                  }
                })() ? 'has-requirement' : 'missing-requirement'
              }
            >
              {req}
            </span>
          ))}
        </div>
      )}
      <div className="recipe-ingredients">
        <span className="label">Requires:</span>
        <div className="resource-list">
          {Object.entries(recipe.ingredients).map(([item, amount]) => (
            <span 
              key={item} 
              className={`resource-item ${inventory[item] >= amount ? 'has-resources' : 'missing-resources'}`}
            >
              {getEmoji(item)} {amount} {item} 
              <span className="inventory-count">
                (have: {inventory[item]})
              </span>
            </span>
          ))}
        </div>
      </div>
      <div className="recipe-output">
        <span className="label">Creates:</span>
        <div className="resource-list">
          {Object.entries(recipe.output).map(([item, amount]) => (
            <span key={item} className="resource-item">
              {getEmoji(item)} {amount} {
                item === 'millStructure' ? 'Wheat Mill' :
                item === 'ovenStructure' ? "Baker's Oven" :
                item === 'silo' ? 'Storage Silo' :
                item === 'marketStall' ? 'Market Stall' :
                item
              }
            </span>
          ))}
        </div>
      </div>
      <button 
        className="craft-button"
        onClick={(e) => {
          if (!canCraft || !requirementsMet) return;
          
          onCraft(recipe);
          
          // Add floating text for ingredients consumed
          Object.entries(recipe.ingredients).forEach(([item, amount], index) => {
            addFloatingText(
              `-${amount} ${getEmoji(item)}`, 
              e.clientX - 20, 
              e.clientY - 20 + (index * 20), 
              '#FF4444'
            );
          });
          
          // Add floating text for items created
          Object.entries(recipe.output).forEach(([item, amount], index) => {
            addFloatingText(
              `+${amount} ${getEmoji(item)}`, 
              e.clientX + 20, 
              e.clientY - 20 + (index * 20), 
              '#4CAF50'
            );
          });
        }}
        disabled={!canCraft || !requirementsMet}
      >
        {recipe.output.millStructure || recipe.output.ovenStructure || 
         recipe.output.silo || recipe.output.marketStall 
          ? "Place Structure" 
          : "Craft"}
      </button>
    </div>
  );
};

interface MarketCardProps {
  recipe: Recipe;
  onSell: (recipe: Recipe) => void;
  inventory: { [key: string]: number };
}

const MarketCard: React.FC<MarketCardProps> = ({ recipe, onSell, inventory }) => {
  const canSell = Object.entries(recipe.ingredients).every(
    ([resource, amount]) => inventory[resource] >= amount
  );

  // Helper function to get emoji for resource
  const getEmoji = (resource: string) => {
    const emojiMap: { [key: string]: string } = {
      wheat: "🌾",
      bread: "🍞",
      coins: "💰",
      gems: "💎"
    };
    return emojiMap[resource] || "";
  };

  const totalValue = Object.entries(recipe.output)
    .reduce((sum, [item, amount]) => sum + amount, 0);

  return (
    <div className={`market-card ${canSell ? 'available' : 'unavailable'}`}>
      <h3>{recipe.name}</h3>
      <div className="market-ingredients">
        <span className="label">Sell:</span>
        <div className="resource-list">
          {Object.entries(recipe.ingredients).map(([item, amount]) => (
            <span 
              key={item} 
              className={`resource-item ${inventory[item] >= amount ? 'has-resources' : 'missing-resources'}`}
              title={`You have: ${inventory[item]}`}
            >
              {getEmoji(item)} {amount} {item}
            </span>
          ))}
        </div>
      </div>
      <div className="market-price">
        <span className="label">Receive:</span>
        <div className="resource-list">
          {Object.entries(recipe.output).map(([item, amount]) => (
            <span key={item} className="price">
              {getEmoji(item)} {amount} {item}
            </span>
          ))}
        </div>
      </div>
      <div className="market-value">
        Total Value: 💰 {totalValue}
      </div>
      <button 
        className="sell-button"
        onClick={() => onSell(recipe)}
        disabled={!canSell}
      >
        Sell
      </button>
    </div>
  );
};

// Add these interfaces near your other interfaces
interface FloatingText {
  id: string;
  text: string;
  x: number;
  y: number;
  color: string;
  timestamp: number;
}

// Add this component
const FloatingNotification: React.FC<{notification: FloatingText}> = ({ notification }) => {
  const timeSinceCreation = Date.now() - notification.timestamp;
  
  // Ensure we don't render invalid notifications
  if (timeSinceCreation >= 1000 || timeSinceCreation < 0) return null;
  
  const opacity = Math.max(0, Math.min(1, 1 - (timeSinceCreation / 1000)));
  const translateY = -40 * (timeSinceCreation / 1000);

  return (
    <div 
      className="floating-notification"
      style={{
        position: 'fixed',
        left: notification.x,
        top: notification.y,
        transform: `translateY(${translateY}px)`,
        opacity,
        color: notification.color,
        pointerEvents: 'none',
        zIndex: 9999,
      }}
    >
      {notification.text}
    </div>
  );
};

// Update interfaces
interface MillPosition {
  row: number;
  col: number;
}

interface ScreenPosition {
  x: number;
  y: number;
}

interface MillData {
  id: string;
  active: boolean;
  progress: number;
  level: number;
  position: MillPosition;
}

interface SelectedMill {
  millId: string;
  screenPosition: ScreenPosition;
}


// Update MillMenu props interface
interface MillMenuProps {
  mill: MillData;
  screenPosition: ScreenPosition;
  onClose: () => void;
  onToggle: (id: string) => void;
  onUpgrade: (id: string) => void;
  inventory: { [key: string]: number };
}

// Update MillMenu component
const MillMenu: React.FC<MillMenuProps> = ({ 
  mill, 
  screenPosition, 
  onClose, 
  onToggle, 
  onUpgrade, 
  inventory 
}) => {
  const upgradeCost = {
    bricks: 3 + mill.level * 2,
    tools: 1 + Math.floor(mill.level / 2)
  };
  
  const canUpgrade = Object.entries(upgradeCost).every(
    ([resource, amount]) => inventory[resource] >= amount
  );

  const processTime = Math.max(50 - mill.level * 5, 10);
  
  return (
    <div 
      className="mill-dropdown"
      style={{ 
        top: `${screenPosition.y + 45}px`,
        left: `${screenPosition.x - 125}px`
      }}
      onClick={e => e.stopPropagation()}
    >
      <div className="mill-content">
        <div className="mill-header">
          <h4>Mill Level {mill.level}</h4>
          <button className="close-mill" onClick={onClose}>×</button>
        </div>

        <div className="mill-stats">
          <div>Speed: {processTime}s</div>
          <div>Output: {(1 + mill.level * 0.2).toFixed(1)}x</div>
        </div>

        <div className="mill-resources">
          <div>Wheat: {inventory.wheat}</div>
          <div>Flour: {inventory.flour}</div>
        </div>

        <div className="mill-progress-bar">
          <div 
            className="progress-fill"
            style={{ width: `${(mill.progress / processTime) * 100}%` }}
          />
        </div>

        <div className="mill-buttons">
          <button 
            className={`mill-toggle ${mill.active ? 'active' : ''}`}
            onClick={() => onToggle(mill.id)}
          >
            {mill.active ? 'Stop' : 'Start'} Mill
          </button>
          
          <button 
            className="mill-upgrade"
            onClick={() => onUpgrade(mill.id)}
            disabled={!canUpgrade}
          >
            Upgrade ({upgradeCost.bricks}🧱, {upgradeCost.tools}🔨)
          </button>
        </div>
      </div>
    </div>
  );
};

// Add to your interfaces section
interface OvenData {
  id: string;
  active: boolean;
  progress: number;
  level: number;
  position: MillPosition; // We can reuse MillPosition type
}

interface SelectedOven {
  ovenId: string;
  screenPosition: ScreenPosition;
}

interface OvenMenuProps {
  oven: OvenData;
  screenPosition: ScreenPosition;
  onClose: () => void;
  onToggle: (id: string) => void;
  onUpgrade: (id: string) => void;
  inventory: { [key: string]: number };
}

// Add new component for Oven Menu
const OvenMenu: React.FC<OvenMenuProps> = ({ 
  oven, 
  screenPosition, 
  onClose, 
  onToggle, 
  onUpgrade, 
  inventory 
}) => {
  const upgradeCost = {
    bricks: 3 + oven.level * 2,
    tools: 1 + Math.floor(oven.level / 2)
  };
  
  const canUpgrade = Object.entries(upgradeCost).every(
    ([resource, amount]) => inventory[resource as keyof typeof inventory] >= amount
  );

  const processTime = Math.max(40 - oven.level * 4, 8); // Slightly faster than mill
  
  return (
    <div 
      className="mill-dropdown" // We can reuse mill styles
      style={{ 
        top: `${screenPosition.y + 45}px`,
        left: `${screenPosition.x - 125}px`
      }}
      onClick={e => e.stopPropagation()}
    >
      <div className="mill-content">
        <div className="mill-header">
          <h4>Oven Level {oven.level}</h4>
          <button className="close-mill" onClick={onClose}>×</button>
        </div>

        <div className="mill-stats">
          <div>Speed: {processTime}s</div>
          <div>Output: {(1 + oven.level * 0.2).toFixed(1)}x</div>
        </div>

        <div className="mill-resources">
          <div>Flour: {inventory.flour}</div>
          <div>Bread: {inventory.bread}</div>
        </div>

        <div className="mill-progress-bar">
          <div 
            className="progress-fill"
            style={{ width: `${(oven.progress / processTime) * 100}%` }}
          />
        </div>

        <div className="mill-buttons">
          <button 
            className={`mill-toggle ${oven.active ? 'active' : ''}`}
            onClick={() => onToggle(oven.id)}
          >
            {oven.active ? 'Stop' : 'Start'} Oven
          </button>
          
          <button 
            className="mill-upgrade"
            onClick={() => onUpgrade(oven.id)}
            disabled={!canUpgrade}
          >
            Upgrade ({upgradeCost.bricks}🧱, {upgradeCost.tools}🔨)
          </button>
        </div>
      </div>
    </div>
  );
};

// Add new interface for save files
interface SaveFile {
  name: string;
  date: string;
  gameState: any;
}

// Add these type definitions near your other interfaces/types
interface Mill {
  id: string;
  level: number;
  active: boolean;
  progress: number;
  position: {
    row: number;
    col: number;
  };
}

const App: React.FC = () => {
  // Modified initial grid generation
  const generateInitialGrid = () => {
    return Array.from({ length: 9 }, () => 
      Array.from({ length: 9 }, () => {
        const random = Math.random();
        if (random < 0.80) return "🌱";      // 60% chance for grass
        if (random < 0.95) return "🪨";      // 20% chance for rocks
        return Math.random() < 0.5 ? "🌲" : "🌳";  // 20% chance for trees (split between two types)
      })
    );
  };

  const initialGrid = generateInitialGrid();
  const [grid, setGrid] = useState<string[][]>(initialGrid);
  const [inventory, setInventory] = useState({
    fiber: 20,
    wheatSeeds: 20,
    clay: 20,
    rocks: 20,
    wheat: 20,
    coins: 20,
    gems: 20,
    wood: 20,
    bricks: 20,
    tools: 20,
    millStructure: 0,
    flour: 0,
    ovenStructure: 0,
    bread: 0,
    silo: 0,
    wheatReserve: 0,
    marketStall: 0
  });
  const [growthTimers, setGrowthTimers] = useState<{[key: string]: number}>({});
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [upgrades, setUpgrades] = useState<Upgrade[]>([
    // Tier 1 - Basic Tools
    {
      name: "Stone Tools",
      cost: { rocks: 20 },
      description: "Reduces tilling cost by 3 fiber (Current: -0 fiber)",
      purchased: false,
      maxLevel: 3,
      level: 0,
      tier: 1
    },
    {
      name: "Quick Harvesting",
      cost: { fiber: 30 },
      description: "Double all harvest yields (wheat, seeds, fiber)",
      purchased: false,
      tier: 1
    },
    // Tier 2 - Basic Automation
    {
      name: "Auto Harvester",
      cost: { fiber: 100, clay: 15 },
      description: "Automatically harvests grown wheat",
      purchased: false,
      tier: 2,
      requires: ["Quick Harvesting"]
    },
    {
      name: "Auto Planter",
      cost: { wheat: 50, rocks: 30 },
      description: "Automatically plants seeds in tilled soil",
      purchased: false,
      tier: 2,
      requires: ["Quick Harvesting"]
    },
    // Tier 3 - Automation quests
    {
      name: "Weather Control",
      cost: { coins: 1000, rocks: 50 },
      description: "Influence weather patterns",
      purchased: false,
      tier: 3,
      requires: ["Auto Harvester"]
    },
    {
      name: "Field Expansion",
      cost: { coins: 500, clay: 100 },
      description: "Increase field size by 1",
      purchased: false,
      maxLevel: 3,
      level: 0,
      tier: 3,
      requires: ["Weather Control"]
    },
    {
      name: "Growth Accelerator",
      cost: { coins: 300, wheat: 100 },
      description: "Reduces growth time by 20%",
      purchased: false,
      maxLevel: 5,
      level: 0,
      tier: 3,
      requires: ["Weather Control"]
    },
    {
      name: "Clay Storage",
      cost: { clay: 50 },
      description: "Increases resource gains by 20%",
      purchased: false,
      maxLevel: 3,
      level: 0,
      tier: 3,
      requires: ["Weather Control"]
    },
    {
      name: "Rock Crusher",
      cost: { rocks: 30, clay: 20 },
      description: "Chance to get gems when harvesting rocks",
      purchased: false,
      tier: 3,
      requires: ["Weather Control"]
    },
    {
      name: "Multi-Harvest",
      cost: { fiber: 150, clay: 30 },
      description: "Harvest adjacent tiles automatically",
      purchased: false,
      tier: 3,
      requires: ["Weather Control"]
    },
    {
      name: "Advanced Irrigation",
      cost: { clay: 100, rocks: 50 },
      description: "Plants grow 50% faster in rainy weather",
      purchased: false,
      tier: 3,
      requires: ["Weather Control"]
    },
    {
      name: "Auto Clicking",
      cost: { tools: 5 },
      description: "Increases auto-click speed by 0.2s (Current: 1.5s)",
      purchased: false,
      maxLevel: 5,
      level: 0,
      tier: 1
    }
  ]);
  const [stats, setStats] = useState<GameStats>({
    clicks: 0,
    totalHarvested: 0,
    totalWheatGrown: 0,
    totalCoinsEarned: 0,
    totalUpgradesPurchased: 0,
    prestigeCount: 0
  });

  const [weather, setWeather] = useState<Weather>({ 
    type: 'sunny', 
    modifier: 1, 
    duration: 60 
  });
  
  const [achievements] = useState<Achievement[]>([
    {
      id: 'click-100',
      name: 'Busy Farmer',
      description: 'Click 100 times',
      condition: (stats) => stats.clicks >= 100,
      unlocked: false,
      reward: { type: 'coins', amount: 50 }
    },
    {
      id: 'wheat-1000',
      name: 'Wheat Master',
      description: 'Grow 1000 wheat',
      condition: (stats) => stats.totalWheatGrown >= 1000,
      unlocked: false,
      reward: { type: 'prestige', amount: 1 }
    },
    // Add more achievements...
  ]);

  const [recipes, setRecipes] = useState<Recipe[]>([
    // Basic Tools & Materials - Always visible
    {
      name: "Basic Tools",
      ingredients: { wood: 3, rocks: 2 },
      output: { tools: 1 },
      unlocked: true,
      type: "craft",
      requires: []
    },
    {
      name: "Bricks",
      ingredients: { clay: 3 },
      output: { bricks: 1 },
      unlocked: true,
      type: "craft",
      requires: []
    },
    // Progression Structures - Visible but locked initially
    {
      name: "Wheat Mill",
      ingredients: { bricks: 5, tools: 2 },
      output: { millStructure: 1 },
      unlocked: false,
      type: "craft",
      requires: ["Basic Tools"]
    },
    {
      name: "Baker's Oven",
      ingredients: { bricks: 8, tools: 3 },
      output: { ovenStructure: 1 },
      unlocked: false,
      type: "craft",
      requires: ["Wheat Mill"]
    },
    {
      name: "Storage Silo",
      ingredients: { bricks: 10, tools: 2 },
      output: { silo: 1 },
      unlocked: false,
      type: "craft",
      requires: ["Baker's Oven"]
    },
    {
      name: "Market Stall",
      ingredients: { wood: 10, tools: 2 },
      output: { marketStall: 1 },
      unlocked: false,
      type: "craft",
      requires: ["Storage Silo"]
    }
  ]);

  const [quests, setQuests] = useState<Quest[]>([
    // Tier 1 - Getting Started
    {
      id: "gather-basics",
      name: "Resource Gatherer",
      description: "Gather 5 wood and 5 rocks to craft your first tools",
      requirements: { wood: 5, rocks: 5 },
      reward: { tools: 1 },
      completed: false,
      tier: 1,
      hidden: false
    },
    {
      id: "first-tools",
      name: "Tool Maker",
      description: "Craft your first set of Basic Tools",
      requirements: { tools: 1 },
      reward: { clay: 10 },
      completed: false,
      tier: 1,
      hidden: false
    },
    // Tier 2 - Basic Production
    {
      id: "brick-maker",
      name: "Brick by Brick",
      description: "Craft 10 bricks for construction",
      requirements: { bricks: 10 },
      reward: { tools: 2 },
      completed: false,
      tier: 2,
      hidden: false
    },
    {
      id: "build-mill",
      name: "Miller Time",
      description: "Build your first Wheat Mill",
      requirements: { millStructure: 1 },
      reward: { wheat: 20 },
      completed: false,
      tier: 2,
      hidden: false
    },
    // Tier 3 - Advanced Production
    {
      id: "flour-production",
      name: "Flour Power",
      description: "Produce 10 flour using your mill",
      requirements: { flour: 10 },
      reward: { bricks: 8, tools: 3 },
      completed: false,
      tier: 3,
      hidden: false
    },
    {
      id: "build-oven",
      name: "Master Baker",
      description: "Build the Baker's Oven",
      requirements: { ovenStructure: 1 },
      reward: { flour: 10 },
      completed: false,
      tier: 3,
      hidden: false
    },
    // Tier 4 - Commerce
    {
      id: "build-market",
      name: "Market Maker",
      description: "Build the Market Stall to start selling goods",
      requirements: { marketStall: 1 },
      reward: { coins: 50 },
      completed: false,
      tier: 4,
      hidden: false
    },
    {
      id: "first-sales",
      name: "First Profits",
      description: "Earn your first 100 coins from selling goods",
      requirements: { coins: 100 },
      reward: { gems: 1 },
      completed: false,
      tier: 4,
      hidden: false
    }
  ]);

  // Add quest tier tracking
  const [currentQuestTier, setCurrentQuestTier] = useState(1);

  // Modify quest checking to handle tiers
  useEffect(() => {
    const completedTierQuests = quests.filter(q => 
      q.tier === currentQuestTier && q.completed
    ).length;
    
    const totalTierQuests = quests.filter(q => 
      q.tier === currentQuestTier
    ).length;

    // If all quests in current tier are complete, unlock next tier
    if (completedTierQuests === totalTierQuests) {
      setCurrentQuestTier(prev => prev + 1);
      // Reveal next tier quests
      setQuests(prev => prev.map(quest => 
        quest.tier === currentQuestTier + 1 
          ? { ...quest, hidden: false }
          : quest
      ));
    }
  }, [quests, currentQuestTier]);

  // Add growth system
  useEffect(() => {
    const timer = setInterval(() => {
      setGrowthTimers(prev => {
        const newTimers = { ...prev };
        Object.keys(newTimers).forEach(key => {
          newTimers[key] = newTimers[key] + 1;
        });
        return newTimers;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Add auto-harvest system
  useEffect(() => {
    const hasAutoHarvester = upgrades.find(u => u.name === "Auto Harvester")?.purchased;
    if (!hasAutoHarvester) return;

    const timer = setInterval(() => {
      setGrid(prevGrid => {
        let newGrid = prevGrid.map(row => [...row]);
        let harvested = false;
        
        for (let i = 0; i < newGrid.length; i++) {
          for (let j = 0; j < newGrid[i].length; j++) {
            if (newGrid[i][j] === "🌾") {
              const coinsEarned = 1;
              setInventory(prev => ({
                ...prev,
                wheat: prev.wheat + Math.floor(Math.random() * 3) + 1,
                wheatSeeds: prev.wheatSeeds + Math.floor(Math.random() * 2),
                coins: prev.coins + coinsEarned
              }));
              setStats(prev => ({
                ...prev,
                totalCoinsEarned: prev.totalCoinsEarned + coinsEarned
              }));
              newGrid[i][j] = "\uD83C\uDF3F";
              harvested = true;
            }
          }
        }
        
        if (harvested) {
          setStats(prev => ({
            ...prev,
            totalHarvested: prev.totalHarvested + 1
          }));
          return newGrid;
        }
        return prevGrid;
      });
    }, 2000);

    return () => clearInterval(timer);
  }, [upgrades]);

  // Add auto-plant system
  useEffect(() => {
    const hasAutoPlanter = upgrades.find(u => u.name === "Auto Planter")?.purchased;
    if (!hasAutoPlanter) return;

    const timer = setInterval(() => {
      setGrid(prevGrid => {
        let newGrid = prevGrid.map(row => [...row]);
        let planted = false;

        if (inventory.wheatSeeds <= 0) return prevGrid;

        for (let i = 0; i < newGrid.length; i++) {
          for (let j = 0; j < newGrid[i].length; j++) {
            if (newGrid[i][j] === "\uD83C\uDF3F") { // Tilled soil
              setInventory(prev => ({...prev, wheatSeeds: prev.wheatSeeds - 1}));
              newGrid[i][j] = "\uD83C\uDF3E"; // Growing wheat
              setGrowthTimers(prev => ({...prev, [`${i}-${j}`]: 0}));
              planted = true;
              break;
            }
          }
          if (planted) break;
        }
        
        return planted ? newGrid : prevGrid;
      });
    }, 3000);

    return () => clearInterval(timer);
  }, [upgrades, inventory.wheatSeeds]);

  // Add weather system
  useEffect(() => {
    const weatherTimer = setInterval(() => {
      const weatherTypes: Weather[] = [
        { type: 'sunny', modifier: 1, duration: 60 },
        { type: 'rainy', modifier: 1.5, duration: 30 },
        { type: 'stormy', modifier: 0.5, duration: 20 },
        { type: 'drought', modifier: 0.25, duration: 40 }
      ];
      
      const hasWeatherControl = upgrades.find(u => u.name === "Weather Control")?.purchased;
      if (hasWeatherControl) {
        // Better weather chances with upgrade
        setWeather(weatherTypes.filter(w => w.modifier >= 1)[
          Math.floor(Math.random() * 2)
        ]);
      } else {
        setWeather(weatherTypes[Math.floor(Math.random() * weatherTypes.length)]);
      }
    }, weather.duration * 1000);

    return () => clearInterval(weatherTimer);
  }, [weather.duration, upgrades]);

  // Add prestige system
  const [prestigePoints, setPrestigePoints] = useState(0);
  const [prestigeMultiplier, setPrestigeMultiplier] = useState(1);

  const prestige = () => {
    if (inventory.wheat >= 1000) {
      setPrestigePoints(prev => prev + Math.floor(inventory.wheat / 1000));
      setPrestigeMultiplier(prev => prev + 0.1);
      
      // Reset game but keep prestige-related stats
      setInventory({
        fiber: 0,
        wheatSeeds: 10,
        clay: 0,
        rocks: 0,
        wheat: 0,
        coins: Math.floor(inventory.coins * 0.1),
        gems: Math.floor(inventory.gems * 0.25),
        bricks: 0,
        tools: 0,
        millStructure: 0,
        flour: 0,
        ovenStructure: 0,
        bread: 0,
        silo: 0,
        wheatReserve: 0,
        marketStall: 0,
        wood: 0
      });
      
      setGrid(initialGrid);
      setUpgrades(prev => prev.map(u => ({...u, purchased: false, level: 0})));
      setStats(prev => ({
        ...prev,
        prestigeCount: prev.prestigeCount + 1
      }));
    }
  };

  // Modified growth system with weather and prestige effects
  useEffect(() => {
    const timer = setInterval(() => {
      setGrid((prevGrid) => {
        let newGrid = prevGrid.map((row) => [...row]);
        let updated = false;
        
        const growthAccelerator = upgrades.find(u => u.name === "Growth Accelerator");
        const growthSpeed = growthAccelerator ? 
          1 - (0.2 * (growthAccelerator.level || 0)) : 1;
        
        for (let i = 0; i < newGrid.length; i++) {
          for (let j = 0; j < newGrid[i].length; j++) {
            const key = `${i}-${j}`;
            const adjustedGrowthTime = 5 * growthSpeed / (weather.modifier * prestigeMultiplier);
            
            if (newGrid[i][j] === "\uD83C\uDF3E" && growthTimers[key] >= adjustedGrowthTime) {
              newGrid[i][j] = "🌾";
              updated = true;
              setStats(prev => ({
                ...prev,
                totalWheatGrown: prev.totalWheatGrown + 1
              }));
            }
          }
        }
        
        if (updated) return newGrid;
        return prevGrid;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [growthTimers, weather.modifier, prestigeMultiplier, upgrades]);

  // Add field expansion logic
  useEffect(() => {
    const expansion = upgrades.find(u => u.name === "Field Expansion");
    if (expansion?.level) {
      const newSize = 9 + expansion.level;
      setGrid(prev => {
        const newGrid = Array.from(
          { length: newSize }, 
          () => Array(newSize).fill("\uD83C\uDF31")
        );
        
        // Copy existing field
        prev.forEach((row, i) => {
          row.forEach((cell, j) => {
            newGrid[i][j] = cell;
          });
        });
        
        return newGrid;
      });
    }
  }, [upgrades]);

  // Add these near the top of the App component, after the state declarations
  const rockCrusher = upgrades.find(u => u.name === "Rock Crusher")?.purchased;
  const multiHarvest = upgrades.find(u => u.name === "Multi-Harvest")?.purchased;
  const stoneTools = upgrades.find(u => u.name === "Stone Tools");
  const tillingCost = 10 - (stoneTools?.level || 0) * 3;
  const resourceMultiplier = 1 + (upgrades.find(u => u.name === "Clay Storage")?.level || 0) * 0.2;

  // At the top with other state declarations
  const [placingStructure, setPlacingStructure] = useState<string | null>(null);

  // Modify the original craftRecipe function instead of declaring a new one
  const craftRecipe = (recipe: Recipe) => {
    // Check if this is a structure recipe
    if (recipe.output.millStructure || recipe.output.ovenStructure || 
        recipe.output.silo || recipe.output.marketStall) {
      setSelectedAction(null); // Clear any other selected action
      setPlacingStructure(Object.keys(recipe.output)[0]);
      return;
    }

    const canCraft = Object.entries(recipe.ingredients).every(
      ([resource, amount]) => inventory[resource as keyof typeof inventory] >= amount
    );

    if (!canCraft) return;

    const marketBonus = upgrades.find(u => u.name === "Market Trading")?.purchased ? 1.5 : 1;

    setInventory(prev => {
      const newInventory = {...prev};
      // Deduct ingredients
      Object.entries(recipe.ingredients).forEach(([resource, amount]) => {
        const key = resource as keyof typeof inventory;
        newInventory[key] -= amount;
      });
      // Add outputs with market bonus
      Object.entries(recipe.output).forEach(([resource, amount]) => {
        const key = resource as keyof typeof inventory;
        newInventory[key] += Math.floor(amount * marketBonus);
      });
      return newInventory;
    });
  };

  // Update the handleTileClick function to include rock and tree harvesting
  const handleTileClick = (rowIndex: number, cellIndex: number, event: React.MouseEvent) => {
    const currentTile = grid[rowIndex][cellIndex];
    const newGrid = [...grid];

    // Check for oven interaction
    if (currentTile === "🏭") {
      const oven = ovens.find(o => 
        o.position.row === rowIndex && o.position.col === cellIndex
      );
      if (oven) {
        setSelectedOven({
          ovenId: oven.id,
          screenPosition: { x: event.clientX, y: event.clientY }
        });
      }
      return;
    }

    // Handle structure placement
    if (placingStructure) {
      if (currentTile === "🟫") {
        // Find the corresponding recipe
        const structureRecipe = recipes.find(r => r.output[placingStructure]);
        
        if (structureRecipe) {
          // Check if we can afford the structure
          const canAfford = Object.entries(structureRecipe.ingredients).every(
            ([resource, amount]) => inventory[resource as keyof typeof inventory] >= amount
          );

          if (canAfford) {
            if (placingStructure === "millStructure") {
              // Create new mill
              const newMill: MillData = {
                id: `mill-${Date.now()}-${rowIndex}-${cellIndex}`,
                active: false,
                progress: 0,
                level: 1,
                position: { row: rowIndex, col: cellIndex }
              };
              setMills(prev => [...prev, newMill]);
              
              // Update the grid
              newGrid[rowIndex][cellIndex] = "⚙️";
              
              // Deduct resources only after successful placement
              setInventory(prev => {
                const newInventory = {...prev};
                Object.entries(structureRecipe.ingredients).forEach(([resource, amount]) => {
                  const key = resource as keyof typeof inventory;
                  newInventory[key] -= amount;
                });
                return newInventory;
              });
            } else if (placingStructure === "ovenStructure") {
              // Create new oven
              const newOven: OvenData = {
                id: `oven-${Date.now()}-${rowIndex}-${cellIndex}`,
                active: false,
                progress: 0,
                level: 1,
                position: { row: rowIndex, col: cellIndex }
              };
              setOvens(prev => [...prev, newOven]);
              
              // Update the grid
              newGrid[rowIndex][cellIndex] = "🏭";
              
              // Deduct resources only after successful placement
              setInventory(prev => {
                const newInventory = {...prev};
                Object.entries(structureRecipe.ingredients).forEach(([resource, amount]) => {
                  const key = resource as keyof typeof inventory;
                  newInventory[key] -= amount;
                });
                return newInventory;
              });
            } else if (placingStructure === "silo") {
              newGrid[rowIndex][cellIndex] = "🏗️";
              // Deduct resources only after successful placement
              setInventory(prev => {
                const newInventory = {...prev, silo: prev.silo + 1};
                Object.entries(structureRecipe.ingredients).forEach(([resource, amount]) => {
                  const key = resource as keyof typeof inventory;
                  newInventory[key] -= amount;
                });
                return newInventory;
              });
            } else if (placingStructure === "marketStall") {
              newGrid[rowIndex][cellIndex] = "🏪";
              // Deduct resources only after successful placement
              setInventory(prev => {
                const newInventory = {...prev, marketStall: prev.marketStall + 1};
                Object.entries(structureRecipe.ingredients).forEach(([resource, amount]) => {
                  const key = resource as keyof typeof inventory;
                  newInventory[key] -= amount;
                });
                return newInventory;
              });
            }
            
            // Clear placing structure mode after successful placement
            setPlacingStructure(null);
          }
        }
      }
      return newGrid;
    }

    // Check for mill interaction first, before any other actions
    if (grid[rowIndex][cellIndex] === "⚙️") {
      const mill = mills.find(m => 
        m.position.row === rowIndex && m.position.col === cellIndex
      );
      if (mill) {
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        setSelectedMill({
          millId: mill.id,
          screenPosition: {
            x: rect.left,
            y: rect.top
          }
        });
        return;
      }
    }

    if (!selectedAction) return;

    setGrid(prevGrid => {
      const newGrid = prevGrid.map(row => [...row]);
      const currentTile = newGrid[rowIndex][cellIndex];
      
      if (selectedAction === "harvest") {
        const currentTile = grid[rowIndex][cellIndex];
    
        if (currentTile === "🌿" || currentTile === "🌱") { // Tall grass
          const quickHarvesting = upgrades.find(u => u.name === "Quick Harvesting")?.purchased;
          const fiberGain = Math.random() > 0.4 ? Math.ceil(1 * resourceMultiplier) * (quickHarvesting ? 2 : 1) : 0;
          const seedGain = Math.random() > 0.5 ? Math.ceil(1 * resourceMultiplier) * (quickHarvesting ? 2 : 1) : 0;
          
          if (fiberGain) {
            addFloatingText(`+${fiberGain} 🌿`, event.clientX, event.clientY, '#4CAF50');
          }
          if (seedGain) {
            addFloatingText(`+${seedGain} 🌱`, event.clientX, event.clientY + 20, '#4CAF50');
          }
          
          setInventory(prev => ({
            ...prev,
            fiber: prev.fiber + fiberGain,
            wheatSeeds: prev.wheatSeeds + seedGain
          }));
          setGrid(prevGrid => {
            const newGrid = prevGrid.map(row => [...row]);
            newGrid[rowIndex][cellIndex] = "🟫";
            return newGrid;
          });
        }
        else if (currentTile === "🌾") { // Grown wheat
          const key = `${rowIndex}-${cellIndex}`;
          const growthAccelerator = upgrades.find(u => u.name === "Growth Accelerator");
          const growthSpeed = growthAccelerator ? 
            1 - (0.2 * (growthAccelerator.level || 0)) : 1;
          const adjustedGrowthTime = 5 * growthSpeed / (weather.modifier * prestigeMultiplier);
          
          // Add this check to ensure wheat is fully grown
          if (growthTimers[key] >= adjustedGrowthTime) {
            const quickHarvesting = upgrades.find(u => u.name === "Quick Harvesting")?.purchased;
            const wheatYield = Math.ceil((Math.floor(Math.random() * 3 + 1) * resourceMultiplier)) * (quickHarvesting ? 2 : 1);
            const seedYield = Math.ceil((Math.floor(Math.random() * 2) * resourceMultiplier)) * (quickHarvesting ? 2 : 1);
    
            addFloatingText(`+${wheatYield} 🌾`, event.clientX, event.clientY, '#4CAF50');
            if (seedYield) {
              addFloatingText(`+${seedYield} 🌱`, event.clientX, event.clientY + 20, '#4CAF50');
            }
    
            setInventory(prev => ({
              ...prev,
              wheat: prev.wheat + wheatYield,
              wheatSeeds: prev.wheatSeeds + seedYield
            }));
            
            setGrid(prevGrid => {
              const newGrid = prevGrid.map(row => [...row]);
              newGrid[rowIndex][cellIndex] = "🟤";
              return newGrid;
            });
            delete growthTimers[`${rowIndex}-${cellIndex}`];
          }
        }
        else if (currentTile === "🪨") { // Rocks
          const rockGain = Math.ceil(1 * resourceMultiplier);
          const clayChance = Math.random();
          const clayGain = clayChance < 0.5 ? Math.ceil(1 * resourceMultiplier) : 0; // 40% chance for clay
          
          addFloatingText(`+${rockGain} 🪨`, event.clientX, event.clientY, '#808080');
          if (clayGain) {
            addFloatingText(`+${clayGain} 🟤`, event.clientX, event.clientY + 20, '#4444FF');
          }
          
          setInventory(prev => ({
            ...prev,
            rocks: prev.rocks + rockGain,
            clay: prev.clay + clayGain
          }));
          newGrid[rowIndex][cellIndex] = "🟫";
        }
        else if (currentTile === "🌲" || currentTile === "🌳") { // Trees
          const woodGain = Math.ceil(1 * resourceMultiplier);
          addFloatingText(`+${woodGain} 🪵`, event.clientX, event.clientY, '#8B4513');
          
          setInventory(prev => ({
            ...prev,
            wood: prev.wood + woodGain
          }));
          newGrid[rowIndex][cellIndex] = "🟫";
        }
      }
      else if (selectedAction === "till" && (currentTile === "🟫" || currentTile === "🌱")) {
        const stoneTools = upgrades.find(u => u.name === "Stone Tools");
        const tillingCost = Math.max(1, 10 - ((stoneTools?.level || 0) * 3));

        if (inventory.fiber >= tillingCost) {
          addFloatingText(`-${tillingCost} 🌿`, event.clientX, event.clientY, '#FF4444');
          
          setInventory(prev => ({
            ...prev,
            fiber: prev.fiber - tillingCost
          }));
          newGrid[rowIndex][cellIndex] = "🟤";
        }
      }
      else if (selectedAction === "plant" && currentTile === "🟤") {
        if (inventory.wheatSeeds > 0) {
          addFloatingText(`-1 🌱`, event.clientX, event.clientY, '#FF4444');
          
          setInventory(prev => ({
            ...prev,
            wheatSeeds: prev.wheatSeeds - 1
          }));
          newGrid[rowIndex][cellIndex] = "🌾";
          setGrowthTimers(prev => ({...prev, [`${rowIndex}-${cellIndex}`]: 0}));
        }
      }

      return newGrid;
    });
  };

  // Update the purchase upgrade function
  const purchaseUpgrade = (upgrade: Upgrade) => {
    const canAfford = Object.entries(upgrade.cost).every(
      ([resource, amount]) => inventory[resource as keyof typeof inventory] >= amount
    );

    if (!canAfford) return;

    setInventory(prev => {
      const newInventory = {...prev};  // This correctly spreads all properties including gems
      Object.entries(upgrade.cost).forEach(([resource, amount]) => {
        // Use type assertion to ensure TypeScript knows this is a valid key
        const key = resource as keyof typeof inventory;
        newInventory[key] = newInventory[key] - amount;
      });
      return newInventory;
    });

    setUpgrades(prev => 
      prev.map(u => {
        if (u.name === upgrade.name) {
          const newLevel = (u.level || 0) + 1;
          return {
            ...u, 
            purchased: true, 
            level: newLevel,
            description: u.name === "Stone Tools" 
              ? `Reduces tilling cost by 3 fiber (Current: -${newLevel * 3} fiber)`
              : u.description
          };
        }
        return u;
      })
    );

    setStats(prev => ({
      ...prev,
      totalUpgradesPurchased: prev.totalUpgradesPurchased + 1
    }));
  };

  // Modify growth system to include Advanced Irrigation
  useEffect(() => {
    const timer = setInterval(() => {
      const advancedIrrigation = upgrades.find(u => u.name === "Advanced Irrigation")?.purchased;
      const weatherModifier = weather.type === 'rainy' && advancedIrrigation ? 
        weather.modifier * 1.5 : weather.modifier;

      setGrid((prevGrid) => {
        let newGrid = prevGrid.map((row) => [...row]);
        let updated = false;
        
        const growthAccelerator = upgrades.find(u => u.name === "Growth Accelerator");
        const growthSpeed = growthAccelerator ? 
          1 - (0.2 * (growthAccelerator.level || 0)) : 1;
        
        for (let i = 0; i < newGrid.length; i++) {
          for (let j = 0; j < newGrid[i].length; j++) {
            const key = `${i}-${j}`;
            const adjustedGrowthTime = 5 * growthSpeed / (weatherModifier * prestigeMultiplier);
            
            if (newGrid[i][j] === "\uD83C\uDF3E" && growthTimers[key] >= adjustedGrowthTime) {
              newGrid[i][j] = "🌾";
              updated = true;
              setStats(prev => ({
                ...prev,
                totalWheatGrown: prev.totalWheatGrown + 1
              }));
            }
          }
        }
        
        if (updated) return newGrid;
        return prevGrid;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [growthTimers, weather, upgrades]);

  // Add quest checking function
  useEffect(() => {
    quests.forEach(quest => {
      if (quest.completed) return;

      const requirementsMet = Object.entries(quest.requirements).every(
        ([resource, amount]) => {
          if (resource === 'farmingUpgrades') {
            const farmingUpgrades = ['Quick Harvesting', 'Auto Harvester'];
            return farmingUpgrades.filter(name => 
              upgrades.find(u => u.name === name)?.purchased
            ).length >= amount;
          }
          return inventory[resource as keyof typeof inventory] >= amount;
        }
      );

      if (requirementsMet) {
        setQuests(prev => 
          prev.map(q => q.id === quest.id ? {...q, completed: true} : q)
        );
        
        // Award rewards
        setInventory(prev => {
          const newInventory = {...prev};
          Object.entries(quest.reward).forEach(([resource, amount]) => {
            const key = resource as keyof typeof inventory;
            newInventory[key] += amount;
          });
          return newInventory;
        });

        // Show notification
        // Add notification state and component for this
      }
    });
  }, [inventory, upgrades]);

  // Add regrowth system for grass
  useEffect(() => {
    const timer = setInterval(() => {
      setGrid(prevGrid => {
        let newGrid = prevGrid.map(row => [...row]);
        let updated = false;

        for (let i = 0; i < newGrid.length; i++) {
          for (let j = 0; j < newGrid[i].length; j++) {
            if (newGrid[i][j] === "🟫") { // Empty tile
              const random = Math.random();
              if (random < 0.05) { // 5% chance of regrowth
                // Determine what grows
                const growthRandom = Math.random();
                if (growthRandom < 0.80) { // 80% chance for grass
                  newGrid[i][j] = "🌱";
                  updated = true;
                } else if (growthRandom < 0.95) { // 15% chance for rocks
                  newGrid[i][j] = "🪨";
                  updated = true;
                } else { // 5% chance for trees
                  newGrid[i][j] = Math.random() < 0.5 ? "🌲" : "🌳";
                  updated = true;
                }
              }
            }
          }
        }

        return updated ? newGrid : prevGrid;
      });
    }, 10000); // Check every 10 seconds

    return () => clearInterval(timer);
  }, []);

  // Update the GrowthProgress component
  const GrowthProgress: React.FC<{row: number, col: number}> = ({row, col}) => {
    const key = `${row}-${col}`;
    const timer = growthTimers[key] || 0;
    const growthAccelerator = upgrades.find(u => u.name === "Growth Accelerator");
    const growthSpeed = growthAccelerator ? 
      1 - (0.2 * (growthAccelerator.level || 0)) : 1;
    const adjustedGrowthTime = 5 * growthSpeed / (weather.modifier * prestigeMultiplier);
    const progress = Math.min((timer / adjustedGrowthTime) * 100, 100);

    return (
      <div className="growth-progress">
        <div 
          className={`growth-progress-bar progress-${Math.round(progress / 10) * 10}`}
        />
      </div>
    );
  };

  // Update the recipe unlock conditions
  useEffect(() => {
    setRecipes(prev => prev.map(recipe => {
      // Check if all required structures/items are present
      const requirementsMet = !recipe.requires || recipe.requires.every(req => {
        switch (req) {
          case "Wheat Mill": return inventory.millStructure > 0;
          case "Baker's Oven": return inventory.ovenStructure > 0;
          case "Storage Silo": return inventory.silo > 0;
          case "Market Stall": return inventory.marketStall > 0;
          case "Basic Tools": return inventory.tools > 0;
          default: return true;
        }
      });

      return { ...recipe, unlocked: requirementsMet };
    }));
  }, [inventory]);

  // Add hover effect for structure placement
  const getCellStyle = (cell: string, rowIndex: number, cellIndex: number) => {
    if (placingStructure && cell === "🟫") {
      return {
        backgroundColor: '#90EE90',
        cursor: 'pointer'
      };
    }
    return {};
  };

  const sellItem = (recipe: Recipe) => {
    const canSell = Object.entries(recipe.ingredients).every(
      ([resource, amount]) => inventory[resource as keyof typeof inventory] >= amount
    );

    if (!canSell) return;

    // Calculate the price based on the recipe's output
    const price = recipe.output.coins || 0; // Assuming 'coins' is the key for the price

    // Assuming you have a reference to the market element or use a different method to get the position
    const marketElement = document.querySelector('.market-section') as HTMLElement;
    const rect = marketElement.getBoundingClientRect();
    addFloatingText(`+${price} 🪙`, rect.left + rect.width / 2, rect.top, '#FFD700');

    // Update inventory and other logic here
  };

  // Add to App component state
  const [floatingTexts, setFloatingTexts] = useState<FloatingText[]>([]);

  // Add this function to create floating notifications
  const addFloatingText = (text: string, x: number, y: number, color: string = '#4CAF50') => {
    const id = Date.now().toString() + Math.random();
    setFloatingTexts(prev => {
      // Keep only the most recent 20 notifications to avoid overwhelming the UI
      const newTexts = [...prev, { id, text, x, y, color, timestamp: Date.now() }];
      return newTexts.slice(-20); 
    });
  };

  // Add this effect to clean up old notifications
  useEffect(() => {
    const cleanup = setInterval(() => {
      setFloatingTexts(prev => prev.filter(text => Date.now() - text.timestamp < 1000));
    }, 500);

    return () => clearInterval(cleanup);
  }, []);

  // Add these near the top with other state declarations
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  // Add this effect to handle theme changes
  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  // Add this function to handle theme toggle
  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };

  // Add this helper function near your other utility functions
  const getWeatherDescription = (weather: Weather): string => {
    switch (weather.type) {
      case 'sunny':
        return 'Perfect growing conditions. Growth speed is normal.';
      case 'rainy':
        return 'Plants grow 50% faster! Even faster with Advanced Irrigation.';
      case 'stormy':
        return 'Bad weather slows growth by 50%.';
      case 'drought':
        return 'Severe conditions slow growth by 75%.';
      default:
        return '';
    }
  };

  // Add to App component state
  const [mills, setMills] = useState<MillData[]>([]);
  const [selectedMill, setSelectedMill] = useState<SelectedMill | null>(null);

  // Update these handler functions inside your App component
  const handleMillToggle = (millId: string) => {
    console.log('Toggling mill:', millId); // Debug log
    setMills(prev => {
      const newMills = prev.map(mill => {
        if (mill.id === millId) {
          const newActive = !mill.active;
          console.log(`Setting mill ${millId} active state to:`, newActive); // Debug log
          return { 
            ...mill, 
            active: newActive,
            progress: newActive ? mill.progress : 0 
          };
        }
        return mill;
      });
      console.log('Updated mills:', newMills); // Debug log
      return newMills;
    });
  };

  const handleMillUpgrade = (millId: string) => {
    setMills(prev => {
      const millToUpgrade = prev.find(m => m.id === millId);
      if (!millToUpgrade) return prev;

      const upgradeCost = (millToUpgrade.level + 1) * 2;
      if (inventory.tools < upgradeCost) return prev;

      setInventory(prevInv => ({
        ...prevInv,
        tools: prevInv.tools - upgradeCost
      }));

      return prev.map(mill => 
        mill.id === millId 
          ? { ...mill, level: mill.level + 1 }
          : mill
      );
    });
  };

  // Update the mill dropdown content to show conversion rates
  const renderMillContent = (mill: Mill) => {
    const flourPerCycle = Math.floor(1 * (1 + mill.level * 0.2));
    return (
      <div className="mill-content">
        <div className="mill-header">
          <h4>Mill Level {mill.level}</h4>
          <button className="close-mill" onClick={() => setSelectedMill(null)}>×</button>
        </div>
        
        <div className="mill-stats">
          <span>Processing Speed: {Math.max(50 - mill.level * 5, 10)}s</span>
        </div>

        <div className="mill-resources">
          <div>
            <div>Converts:</div>
            <div>5 🌾 → {flourPerCycle} 🛍️</div>
          </div>
          <div>
            <div>Efficiency:</div>
            <div>+{(mill.level * 20)}% output</div>
          </div>
        </div>

        <div className="mill-progress-bar">
          <div 
            className="progress-fill" 
            style={{ width: `${(mill.progress / (Math.max(50 - mill.level * 5, 10))) * 100}%` }}
          />
        </div>

        <div className="mill-buttons">
          <button 
            className={`mill-toggle ${mill.active ? 'active' : ''}`}
            onClick={() => handleMillToggle(mill.id)}
          >
            {mill.active ? 'Stop' : 'Start'}
          </button>
          <button 
            className="mill-upgrade"
            onClick={() => handleMillUpgrade(mill.id)}
            disabled={inventory.tools < (mill.level + 1) * 2}
          >
            Upgrade ({(mill.level + 1) * 2} 🔨)
          </button>
        </div>
      </div>
    );
  };

  // Update the mill processing effect
  useEffect(() => {
    const millTimer = setInterval(() => {
      setMills(prevMills => {
        return prevMills.map(mill => {
          if (!mill.active) return mill;

          const processTime = Math.max(50 - mill.level * 5, 10);
          let newProgress = mill.progress + 1;

          if (newProgress >= processTime) {
            if (inventory.wheat >= 5) {
              // Calculate flour produced based on mill level
              const flourProduced = Math.floor(1 * (1 + mill.level * 0.2));
              
              // Update inventory
              setInventory(prev => ({
                ...prev,
                wheat: prev.wheat - 5,
                flour: prev.flour + flourProduced,
              }));

              // Compute position relative to the app container
              const millElement = document.querySelector(`[data-mill-id="${mill.id}"]`);
              if (millElement) {
                const rect = millElement.getBoundingClientRect();
                const containerRect = document.querySelector('.app-container')?.getBoundingClientRect();
                if (containerRect) {
                  const x = rect.left - containerRect.left + rect.width / 2;
                  const y = rect.top - containerRect.top;
                  addFloatingText(`-5 🌾`, x, y, '#ff4444');
                  addFloatingText(`+${flourProduced} 🛍️`, x, y + 20, '#4CAF50');
                }
              }

              // Reset progress
              newProgress = 0;
            } else {
              // Not enough wheat; pause the mill
              return { ...mill, active: false, progress: 0 };
            }
          }

          return { ...mill, progress: newProgress };
        });
      });
    }, 1000); // Adjust interval as needed

    return () => clearInterval(millTimer);
  }, [mills, inventory.wheat]);

  // Add these functions inside your App component
  const toggleMill = (millId: string) => {
    setMills(prev => prev.map(mill => 
      mill.id === millId 
        ? { ...mill, active: !mill.active, progress: !mill.active ? mill.progress : 0 }
        : mill
    ));
  };

  const upgradeMill = (millId: string) => {
    setMills(prev => {
      const millToUpgrade = prev.find(m => m.id === millId);
      if (!millToUpgrade) return prev;

      const upgradeCost = (millToUpgrade.level + 1) * 2;
      if (inventory.tools < upgradeCost) return prev;

      setInventory(prevInv => ({
        ...prevInv,
        tools: prevInv.tools - upgradeCost
      }));

      return prev.map(mill => 
        mill.id === millId 
          ? { ...mill, level: mill.level + 1 }
          : mill
      );
    });
  };

  // Add to App component state
  const [ovens, setOvens] = useState<OvenData[]>([]);
  const [selectedOven, setSelectedOven] = useState<SelectedOven | null>(null);

  // Add these handler functions inside App component
  const handleOvenToggle = (ovenId: string) => {
    setOvens(prev => prev.map(oven => 
      oven.id === ovenId 
        ? { ...oven, active: !oven.active, progress: !oven.active ? oven.progress : 0 }
        : oven
    ));
  };

  const handleOvenUpgrade = (ovenId: string) => {
    setOvens(prev => {
      const ovenToUpgrade = prev.find(o => o.id === ovenId);
      if (!ovenToUpgrade) return prev;

      const upgradeCost = {
        bricks: 3 + ovenToUpgrade.level * 2,
        tools: 1 + Math.floor(ovenToUpgrade.level / 2)
      };

      const canAfford = Object.entries(upgradeCost).every(
        ([resource, amount]) => inventory[resource as keyof typeof inventory] >= amount
      );

      if (!canAfford) return prev;

      setInventory(prevInv => {
        const newInv = { ...prevInv };
        Object.entries(upgradeCost).forEach(([resource, amount]) => {
          const key = resource as keyof typeof inventory;
          newInv[key] -= amount;
        });
        return newInv;
      });

      return prev.map(oven => 
        oven.id === ovenId 
          ? { ...oven, level: oven.level + 1 }
          : oven
      );
    });
  };

  // Update oven processing effect
  useEffect(() => {
    const ovenTimer = setInterval(() => {
      setOvens(prevOvens => {
        let updated = false;
        const newOvens = prevOvens.map(oven => {
          if (!oven.active || inventory.flour < 3) return oven;
          
          const processTime = Math.max(40 - oven.level * 4, 8);
          const newProgress = oven.progress + 1;
          
          if (newProgress >= processTime) {
            // Calculate bread production based on oven level
            const breadProduced = oven.level;
            
            if (!updated && inventory.flour >= 3) {
              // Update inventory
              setInventory(prev => ({
                ...prev,
                flour: prev.flour - 3,
                bread: prev.bread + breadProduced
              }));

              // Find oven element and get its position relative to the app container
              const ovenElement = document.querySelector(`[data-oven-id="${oven.id}"]`);
              if (ovenElement) {
                const rect = ovenElement.getBoundingClientRect();
                const containerRect = document.querySelector('.app-container')?.getBoundingClientRect();
                if (containerRect) {
                  const x = rect.left - containerRect.left + rect.width / 2;
                  const y = rect.top - containerRect.top;
                  addFloatingText('-3 🛍️', x, y, '#ff4444');
                  addFloatingText(`+${breadProduced} 🍞`, x, y + 20, '#4CAF50');
                }
              }
              updated = true;
            }
            return { ...oven, progress: 0 };
          }
          
          return { ...oven, progress: newProgress };
        });
        
        return newOvens;
      });
    }, 1000);

    return () => clearInterval(ovenTimer);
  }, [inventory.flour]);

  // Use useEffect to save the game state whenever relevant variables change
  useEffect(() => {
    const gameState = {
      grid,
      inventory,
      growthTimers,
      selectedAction,
      upgrades,
      stats,
      weather,
      recipes,
      quests,
      currentQuestTier,
      prestigePoints,
      prestigeMultiplier,
      placingStructure,
      isDarkMode,
      mills,
      ovens
    };

    try {
      localStorage.setItem('gameState', JSON.stringify(gameState));
    } catch (error) {
      console.error("Failed to save game state:", error);
    }
  }, [
    grid,
    inventory,
    growthTimers,
    selectedAction,
    upgrades,
    stats,
    weather,
    recipes,
    quests,
    currentQuestTier,
    prestigePoints,
    prestigeMultiplier,
    placingStructure,
    isDarkMode,
    mills,
    ovens
  ]);

  // Add state for save files and mouse tracking
  const [saveFiles, setSaveFiles] = useState<SaveFile[]>(() => {
    try {
      return JSON.parse(localStorage.getItem('saveFiles') || '[]');
    } catch {
      return [];
    }
  });
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [lastAutoClickTime, setLastAutoClickTime] = useState(Date.now());
  const [hoveredCell, setHoveredCell] = useState<{row: number, col: number} | null>(null);

  // Add auto-click effect
  useEffect(() => {
    if (!isMouseDown || !hoveredCell || !selectedAction) return;

    const baseClickRate = 1500; // 1.5 seconds base
    const toolLevel = upgrades.find(u => u.name === "Auto Clicking")?.level || 0;
    const clickRate = Math.max(200, baseClickRate - (toolLevel * 200));

    const interval = setInterval(() => {
      const now = Date.now();
      if (now - lastAutoClickTime >= clickRate) {
        // Create a mock event object with required properties
        const mockEvent = {
          clientX: 0,
          clientY: 0
        } as React.MouseEvent<Element, MouseEvent>;

        handleTileClick(hoveredCell.row, hoveredCell.col, mockEvent);
        setLastAutoClickTime(now);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [isMouseDown, hoveredCell, lastAutoClickTime, selectedAction]);

  // Add save/load functions
  const saveGame = () => {
    const name = prompt("Enter a name for this save:");
    if (!name) return;

    const newSave: SaveFile = {
      name,
      date: new Date().toLocaleString(),
      gameState: {
        grid,
        inventory,
        growthTimers,
        selectedAction,
        upgrades,
        stats,
        weather,
        recipes,
        quests,
        currentQuestTier,
        prestigePoints,
        prestigeMultiplier,
        placingStructure,
        isDarkMode,
        mills,
        ovens
      }
    };

    const updatedSaves = [...saveFiles, newSave];
    setSaveFiles(updatedSaves);
    localStorage.setItem('saveFiles', JSON.stringify(updatedSaves));
    alert("Game saved successfully!");
  };

  const loadGame = (save: SaveFile) => {
    if (!window.confirm("Are you sure? Current progress will be lost.")) return;

    const { gameState } = save;
    setGrid(gameState.grid);
    setInventory(gameState.inventory);
    setGrowthTimers(gameState.growthTimers);
    setSelectedAction(gameState.selectedAction);
    setUpgrades(gameState.upgrades);
    setStats(gameState.stats);
    setWeather(gameState.weather);
    setRecipes(gameState.recipes);
    setQuests(gameState.quests);
    setCurrentQuestTier(gameState.currentQuestTier);
    setPrestigePoints(gameState.prestigePoints);
    setPrestigeMultiplier(gameState.prestigeMultiplier);
    setPlacingStructure(gameState.placingStructure);
    setIsDarkMode(gameState.isDarkMode);
    setMills(gameState.mills);
    setOvens(gameState.ovens);

    alert("Game loaded successfully!");
  };

  const deleteSave = (index: number) => {
    if (!window.confirm("Are you sure you want to delete this save?")) return;

    const updatedSaves = saveFiles.filter((_, i) => i !== index);
    setSaveFiles(updatedSaves);
    localStorage.setItem('saveFiles', JSON.stringify(updatedSaves));
  };

  // Modify your grid rendering to include mouse events
  const renderGrid = () => (
    <div className="grid">
      {grid.map((row: any, rowIndex: number) => (
        <div key={rowIndex} className="row">
          {row.map((cell: any, cellIndex: number) => {
            const mill = mills.find(m => 
              m.position.row === rowIndex && m.position.col === cellIndex
            );
            const oven = ovens.find(o => 
              o.position.row === rowIndex && o.position.col === cellIndex
            );
            
            return (
              <span
                key={cellIndex}
                className={`cell ${
                  placingStructure && cell === "🟫" 
                    ? 'placeable'
                    : cell === "⚙️" || cell === "🏭" || cell === "🏗️" || cell === "🏪"
                    ? 'structure'
                    : cell === "🟫"
                    ? 'empty'
                    : cell === "🌾"
                    ? 'growing'
                    : ''
                }`}
                onClick={(event) => handleTileClick(rowIndex, cellIndex, event)}
                data-mill-id={mill?.id}
                data-oven-id={oven?.id}
                data-market={cell === "🏪" ? "true" : undefined}
              >
                {cell}
                {cell === "🌾" && (
                  <GrowthProgress row={rowIndex} col={cellIndex} />
                )}
              </span>
            );
          })}
        </div>
      ))}
    </div>
  );

  // Add save/load UI to your return statement
  return (
    <div className="app-container">
      <button 
        className="theme-toggle" 
        onClick={toggleTheme}
        title={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`}
      >
        {isDarkMode ? '☀️' : '🌙'}
      </button>



      <div className="weather-container">
        <div className="weather-emoji">
          {weather.type === 'sunny' && '☀️'}
          {weather.type === 'rainy' && '🌧️'}
          {weather.type === 'stormy' && '⛈️'}
          {weather.type === 'drought' && '🏜️'}
        </div>
        <div className="weather-tooltip">
          <strong>{weather.type.charAt(0).toUpperCase() + weather.type.slice(1)}</strong>
          <br />
          {getWeatherDescription(weather)}
        </div>
      </div>
      <h1 className="title">Humble Farming</h1>
      
      <div className="grid-container">
        {grid.map((row, rowIndex) => (
          <div key={rowIndex} className="row">
            {row.map((cell, cellIndex) => (
              <span
                key={cellIndex}
                className={`cell ${
                  placingStructure && cell === "🟫" 
                    ? 'placeable'
                    : cell === "⚙️" || cell === "🏭" || cell === "🏗️" || cell === ""
                    ? 'structure'
                    : cell === "🟫"
                    ? 'empty'
                    : cell === "🌾"
                    ? 'growing'
                    : ''
                }`}
                onClick={(event) => handleTileClick(rowIndex, cellIndex, event)}
              >
                {cell}
                {cell === "🌾" && (
                  <GrowthProgress row={rowIndex} col={cellIndex} />
                )}
              </span>
            ))}
          </div>
        ))}
        
        {/* Floating notifications */}
        {floatingTexts.map(text => (
          <FloatingNotification key={text.id} notification={text} />
        ))}
      </div>
      <div className="buttons">
        <button
          className={`game-button ${selectedAction === "harvest" ? "active" : ""}`}
          onClick={() => {
            setSelectedAction("harvest");
            setPlacingStructure(null);
          }}
        >
          🔪 Harvest
        </button>
        <button
          className={`game-button ${selectedAction === "till" ? "active" : ""}`}
          onClick={() => {
            setSelectedAction("till");
            setPlacingStructure(null);
          }}
          disabled={inventory.fiber < tillingCost}
        >
          ⛏️ Till
        </button>
        <button
          className={`game-button ${selectedAction === "plant" ? "active" : ""}`}
          onClick={() => {
            setSelectedAction("plant");
            setPlacingStructure(null);
          }}
          disabled={inventory.wheatSeeds <= 0}
        >
          🌱 Plant
        </button>
        {placingStructure && (
          <button
            className="game-button active"
            onClick={() => setPlacingStructure(null)}
          >
            ❌ Cancel Placement
          </button>
        )}
      </div>

      <div className="resources">
        {/* Basic Resources */}
        <div className="resource-group">
          <h3>Basic Resources</h3>
          <div className="resource">🌿 Fiber: {inventory.fiber}</div>
          <div className="resource">🌱 Wheat Seeds: {inventory.wheatSeeds}</div>
          <div className="resource">🟤 Clay: {inventory.clay}</div>
          <div className="resource">🪨 Rocks: {inventory.rocks}</div>
          <div className="resource">🪵 Wood: {inventory.wood}</div>
          <div className="resource">🌾 Wheat: {inventory.wheat}</div>
        </div>
        
        {/* Crafted Items */}
        <div className="resource-group">
          <h3>Crafted Items</h3>
          <div className="resource">🧱 Bricks: {inventory.bricks}</div>
          <div className="resource">🔨 Tools: {inventory.tools}</div>
          <div className="resource">🛍️ Flour: {inventory.flour}</div>
          <div className="resource">🍞 Bread: {inventory.bread}</div>
        </div>
        
        {/* Structures - New Section */}
        <div className="resource-group">
          <h3>Structures</h3>
          <div className="resource">⚙️ Mills: {mills.length}</div>
          <div className="resource">🏭 Ovens: {inventory.ovenStructure}</div>
          <div className="resource">🏗️ Silos: {inventory.silo}</div>
          <div className="resource">🏪 Markets: {inventory.marketStall}</div>
        </div>
        
        {/* Currency */}
        <div className="resource-group">
          <h3>Currency</h3>
          <div className="resource">💰 Coins: {inventory.coins}</div>
          <div className="resource">💎 Gems: {inventory.gems}</div>
        </div>
      </div>


      <div className="crafting-section">
        <h2>Crafting</h2>
        <div className="recipe-grid">
          {recipes
            .filter(r => r.unlocked && r.type === "craft")
            .map(recipe => (
              <RecipeCard 
                key={recipe.name} 
                recipe={recipe} 
                onCraft={(recipe) => {
                  craftRecipe(recipe);
                  setSelectedAction(null); // Clear other actions when crafting
                }}
                inventory={inventory}
                addFloatingText={addFloatingText}
              />
            ))}
        </div>
      </div>

      <div className="market-section">
        <h2>Market</h2>
        <div className="recipe-grid">
          {recipes
            .filter(r => r.unlocked && r.type === "market")
            .map(recipe => (
              <MarketCard 
                key={recipe.name} 
                recipe={recipe} 
                onSell={sellItem}
                inventory={inventory}
              />
            ))}
        </div>
      </div>

      <div className="quest-section">
        <h2>Quests</h2>
        <div className="quest-grid">
          {quests
            .filter(q => !q.completed && !q.hidden)
            .map(quest => (
              <div key={quest.id} className="quest-card">
                <h3>{quest.name}</h3>
                <p>{quest.description}</p>
                <div className="quest-rewards">
                  Rewards: 
                  {Object.entries(quest.reward).map(([item, amount]) => (
                    <span key={item}>{item}: {amount}</span>
                  ))}
                </div>
                <div className="quest-tier">Tier {quest.tier}</div>
              </div>
            ))}
        </div>
      </div>
      <div className={`prestige-section ${inventory.wheat >= 1000 ? 'can-prestige' : ''}`}>
        <button 
          onClick={prestige}
          disabled={inventory.wheat < 1000}
          title={inventory.wheat < 1000 ? 
            `Need ${1000 - inventory.wheat} more wheat to prestige` : 
            'Reset progress for permanent bonuses'
          }
        >
          Prestige
        </button>
        <div>Points: {prestigePoints}</div>
        <div>Multiplier: {prestigeMultiplier.toFixed(1)}x</div>
      </div>
      {placingStructure && (
        <div className="placing-structure-indicator">
          Click an empty tile to place {placingStructure.replace('Structure', '')}
        </div>
      )}
      {selectedMill && (
        <MillMenu
          mill={mills.find(mill => mill.id === selectedMill.millId)!}
          screenPosition={selectedMill.screenPosition}
          onClose={() => setSelectedMill(null)}
          onToggle={handleMillToggle}
          onUpgrade={handleMillUpgrade}
          inventory={inventory}
        />
      )}
      {selectedOven && (
        <OvenMenu
          oven={ovens.find(oven => oven.id === selectedOven.ovenId)!}
          screenPosition={selectedOven.screenPosition}
          onClose={() => setSelectedOven(null)}
          onToggle={handleOvenToggle}
          onUpgrade={handleOvenUpgrade}
          inventory={inventory}
        />
      )}

      {/* Save/Load section */}
      <div className="save-load-section">
        <h2>Save Management</h2>
        <button 
          className="save-button"
          onClick={saveGame}
          title="Save your game"
        >
          💾 Save Game
        </button>
        <div className="save-files">
          {saveFiles.map((save, index) => (
            <div key={index} className="save-file">
              <div className="save-info">
                <span className="save-name">{save.name}</span>
                <span className="save-date">{save.date}</span>
              </div>
              <div className="save-actions">
                <button 
                  onClick={() => loadGame(save)}
                  title="Load this save"
                  className="load-button"
                >
                  📂 Load
                </button>
                <button 
                  onClick={() => deleteSave(index)}
                  title="Delete this save"
                  className="delete-button"
                >
                  🗑️ Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

export default App;




